<template>
  <div class="post-warp">
    <div class="post-item" @touchstart="getTouchStart" @touchend="getTouchEnd" @touchmove="clearTouch">
      <!-- header-->
      <div class="post-top" @click="$router.push({ path: '/postDetails', query: { id: postInfo.id } })">
        <div class="post-top-left flex-center-center">
          <div class="post-avatar" @click.stop="jumpUserPage" :class="{ isCertifica: postInfo.publisher.merchantUser == 1 }">
            <ImgDecypt :src="postInfo.publisher.portrait" round :key="postInfo.publisher.portrait" />
            <div class="certification" v-if="postInfo.publisher.superUser == 1">
              <Vlogo :vid="postInfo.id" />
            </div>
            <svg-icon class="certificaBorder" iconClass="certificaBorder" v-if="postInfo.publisher.merchantUser == 1" />
            <!-- <svg-icon class="certification" iconClass="certification"
                      v-if="postInfo.publisher.superUser == 1"></svg-icon> -->
          </div>
          <div class="post-info">
            <div class="username_box ellipsis">
              <span
                :class="{
                  vipName: postInfo.publisher.isVip && postInfo.publisher.vipLevel > 0,
                }"
                @click.stop="jumpUserPage"
              >
                {{ postInfo.publisher.name }}
              </span>
              <svg-icon class="user_icon" iconClass="crown" v-if="postInfo.publisher.isVip && postInfo.publisher.vipLevel > 0"></svg-icon>
              <div class="user_icon" v-for="icon in userIcons" :key="icon.number">
                <ImgDecypt :src="icon.imgUrl" v-if="icon.isExpire" />
              </div>
            </div>
            <!--显示地区-->
            <div class="post_Introduction flex">
              <div class="location ellipsis" @click.stop="openCity">
                <svg-icon icon-class="location" />
                <span>{{ postInfo.location.city }}</span>
              </div>
              <div class="pub_time">{{ postInfo.reviewAt | dateago }}</div>
            </div>
          </div>
        </div>
        <div
          class="post-top-right"
          @click.stop="focus"
          v-if="!postInfo.publisher.hasFollowed && postInfo.publisher.uid != $store.getters.userInfo.uid && !hiddenFollow"
        >
          关注
        </div>
      </div>
      <!--content-->
      <div class="post-text" @click="$router.push({ path: '/postDetails', query: { id: postInfo.id } })">
        <div class="topLabel" v-if="postInfo.publisherTop && $route.path == '/userHomePage'">置顶</div>
        <TextOverflow :text="postInfo.title" :maxLines="3" :chosen="postInfo.chosen" :isTopping="postInfo.isTopping">
          <template v-slot:default="{ clickToggle, expanded }">
            <div @click.stop="clickToggle" class="text-btn">
              {{ expanded ? '收起' : '全文' }}
            </div>
          </template>
        </TextOverflow>
      </div>
      <div
        :class="[gridClass(postInfo.seriesCover.length), 'post_img']"
        v-if="postInfo.newsType == 'COVER'"
        @click.stop="$router.push({ path: '/postDetails', query: { id: postInfo.id } })"
      >
        <ImgDecypt
          :src="src"
          v-for="(src, index) in postInfo.seriesCover.slice(0, 6)"
          :key="src + index"
          class="img_item"
          @click.native.stop="openPreview(postInfo.seriesCover, index)"
        >
          <div v-if="index == 5 && postInfo.seriesCover.length > 6" class="over">+{{ postInfo.seriesCover.length - 6 }}</div>
        </ImgDecypt>
      </div>
      <div
        class="post_video"
        :class="{
          cloumn_post_video: postInfo.ratio < 1 && postInfo.newsType != 'MOVIE',
        }"
        v-if="postInfo.newsType == 'SP' || postInfo.newsType == 'MOVIE'"
        @click="openVideo"
      >
        <ImgDecypt :src="postInfo.cover"></ImgDecypt>
        <div class="play_box">
          <svg-icon icon-class="play" class="play_icon"></svg-icon>
        </div>
      </div>
      <div>
        <div class="post-tag-box oepnX">
          <div class="post-tag" v-for="(item, index) in postInfo.tags" :key="index" @click="openTag(item)">
            <svg-icon icon-class="tag"></svg-icon>
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
      <div class="flex-aic">
        <div class="views" v-if="!isMenuClose">
          <svg-icon icon-class="tip_eye"></svg-icon>
          浏览次数:
          <span>{{ postInfo.playCount | watchCount }}</span>
        </div>
        <div class="post_menu" v-if="!isMenuClose">
          <div class="menu_item" @click="openShare">
            <svg-icon icon-class="share_video"></svg-icon>
            <span class="ellipsis">赚钱</span>
          </div>
          <div class="menu_item" @click="messageClick">
            <svg-icon icon-class="message"></svg-icon>
            <span class="ellipsis">{{ postInfo.commentCount || '评论' }}</span>
          </div>
          <div class="menu_item" @click="collecte">
            <svg-icon :icon-class="postInfo.vidStatus && postInfo.vidStatus.hasCollected ? 'tip_like_active' : 'tip_like'" />
            <span class="ellipsis">
              {{ (postInfo.forwardCount || '点赞') | watchCount }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vlogo from '@/components/Vlogo';
import ImgDecypt from '@/components/ImgDecypt';
import TextOverflow from '@/components/TextOverflow';
// import Forward from "@/components/Forward";
import { cancleZan, careCollectVideo, careOrcancle, zan } from '@/api/user';
import { Toast } from 'vant';
import { mapGetters } from 'vuex';
import { getMedal } from '@/utils/getConfig';
import { getSessionItem, removeSession, setSessionItem } from '@/utils/longStorage';
export default {
  props: {
    isMenuClose: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
    },
    fromPath: {
      type: String,
    },
    page: {
      type: Number,
    },
    activeName: {
      type: String,
    },
    keyword: {
      type: String,
    },
    postInfo: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    hiddenFollow: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    isTop: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  name: 'post',
  components: {
    Vlogo,
    ImgDecypt,
    TextOverflow,
    // Forward,
  },
  data() {
    return {
      isShare: false,
      setTop: '',
      isSetTop: false,
      isClear: false,
      pageShortVideo: 1,
    };
  },
  created() {
    // console.log(this.postInfo.tags)
    this.pageShortVideo = this.page;
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
    }),
    userIcons() {
      return getMedal(this.postInfo?.publisher?.awardsExpire || []) || [];
    },
  },
  methods: {
    gridClass(num) {
      if (num == 1 || num == 2 || num == 4) return 'cover-box-grid2';
      return 'cover-box-grid3';
    },
    messageClick() {
      if (this.userInfo.isVip && this.userInfo.vipLevel > 0) {
        const data = {
          show: true,
          index: this.index,
          params: {
            videoId: this.postInfo.id,
          },
        };
        this.$store.commit('user/UPDATE_COMMENT', data);
      } else {
        // Toast({
        //   message: "只有会员才能评论哦",
        //   duration: 1500,
        // })
        this.$bus.$emit('promptPopupEvent', '您还不是VIP无法查看神评论');
      }
    },
    openShare() {
      this.$store.commit('user/UPDATE_SHARE', {
        show: true,
        info: { ...this.postInfo, isVerScreen: this.postInfo.ratio < 1 },
      });
    },
    //转发成功
    forwardSuccess() {
      this.postInfo.forwardCount++;
    },
    // 取消收藏
    unfavorite() {
      this.postInfo.forwardCount--;
    },
    //前往标签页
    openTag(item) {
      if (getSessionItem('tagId')) {
        removeSession('tagId');
      }
      if (this.$route.path == '/tags/video' || this.$route.path == '/tags/graphics') {
        this.$router.replace({
          path: '/tags/video',
          query: { id: item.id, fromPath: this.$route.path },
        });
      } else {
        this.$router.push({ path: '/tags/video', query: { id: item.id } });
      }
    },
    // 点击头像跳转用户主页
    jumpUserPage() {
      // :to="{path:'/userHomePage',query:{uid:postInfo.publisher.uid, fromPath: $route.path}}"
      if (this.$route.path == '/userHomePage' && this.$route.query != this.postInfo.publisher.uid) {
        this.$router.replace({
          path: '/userHomePage',
          query: {
            uid: this.postInfo.publisher.uid,
            fromPath: this.$route.path,
          },
        });
      } else {
        this.$router.push({
          path: '/userHomePage',
          query: {
            uid: this.postInfo.publisher.uid,
            fromPath: this.$route.path,
          },
        });
      }
    },
    getType(type) {
      switch (type) {
        case 'SP':
          return 'video';
        case 'COVER':
          return 'img';
        default:
          return 'video';
      }
    },
    // 校验是否会员、会员时间是否到期
    checkVip() {
      let uInfo = this.$store.getters.userInfo;
      let todayTime = new Date().getTime();
      let effectiveTime = new Date(uInfo.vipExpireDate).getTime();
      if (uInfo && uInfo.isVip && todayTime < effectiveTime && uInfo.vipLevel > 0) {
        return true;
      }
      return false;
    },
    //收藏
    async collecte() {
      let isCollect = this.postInfo?.vidStatus?.hasCollected;
      let id = this.postInfo?.id;
      let req = {
        type: this.getType(this.postInfo.newsType),
        objIds: [id],
        isCollect: !isCollect,
      };
      try {
        let res = await this.$Api(careCollectVideo, req);
        if (res.code === 200) {
          this.postInfo.vidStatus.hasCollected = !isCollect;
          if (isCollect) {
            Toast('取消点赞');
            this.postInfo.forwardCount--;
          } else {
            Toast('点赞成功');
            this.postInfo.forwardCount++;
          }
          return;
        }
        Toast('操作失败');
      } catch (e) {
        Toast('操作失败');
      }
    },
    //点赞
    async like() {
      let isLike = this.postInfo?.vidStatus?.hasLiked;
      let id = this.postInfo.id;
      let req = {
        objID: id,
        type: 'video',
      };
      try {
        if (isLike) {
          let res = await this.$Api(cancleZan, req);
          if (res.code === 200) {
            this.postInfo.vidStatus.hasLiked = false;
            this.postInfo.likeCount--;
            Toast('取消点赞');
            return;
          }
          Toast(res.tip || '取消失败');
        } else {
          let res = await this.$Api(zan, req);
          if (res.code === 200) {
            this.postInfo.vidStatus.hasLiked = true;
            this.postInfo.likeCount++;
            Toast('点赞成功');
            return;
          }
          Toast(res.tip || '点赞失败');
        }
      } catch (e) {
        Toast('操作失败');
      }
    },
    //关注
    async focus() {
      if (this.postInfo?.publisher.hasFollowed) {
        return;
      }
      let uid = this.postInfo?.publisher?.uid;
      let req = {
        followUID: parseInt(uid),
        isFollow: true,
      };
      if (!uid) {
        Toast('当前用户信息错误');
        return;
      }
      try {
        let res = await this.$Api(careOrcancle, req);
        if (res.code === 200) {
          this.postInfo.publisher.hasFollowed = true;
          this.$emit('followedAllSuccess', uid);
          Toast('关注成功');
          this.$store.commit('video/UPDATE_ALL_FOCUS', uid);
          let followInfo = { uid: uid, status: req.isFollow };
          setSessionItem('followInfo', JSON.stringify(followInfo));
          return;
        }
        Toast(res.tip || '关注失败');
      } catch (e) {
        Toast('关注失败');
      }
    },
    //前往城市页
    openCity() {
      let id = this.postInfo?.location?.id;
      let city = this.postInfo?.location?.city;
      let fromPath = this.$route.query.fromPath;
      if (fromPath == 'citys') {
        this.$router.go(-1);
        return;
      }
      this.$router.push({ path: '/citys', query: { id: id, city: city } });
    },
    openVideo() {
      if (this.postInfo.newsType == 'MOVIE') {
        this.$router.push({
          path: '/horizontalVideo',
          query: {
            id: this.postInfo.id,
          },
        });
        return;
      }
      this.$router.push({
        path: '/communityVideo',
        query: {
          fromPath: this.fromPath,
          fromRoutePath: this.$route.name,
          page: this.pageShortVideo,
          activeName: this.activeName,
          vid: this.postInfo.id,
          keyword: this.keyword,
        },
      });
    },
    openPreview(list, index) {
      this.$router.push({
        name: 'PicturePreview',
        params: {
          imgs: list,
          index: index,
          info: this.postInfo,
          uid: this.postInfo.publisher.uid,
        },
      });
    },
    getTouchStart() {
      if (!this.isTop || this.isClear) {
        this.isClear = false;
        clearTimeout(this.setTop);
        return;
      }
      // event.preventDefault();
      clearTimeout(this.setTop);
      this.setTop = setTimeout(() => {
        let data = {
          id: this.postInfo.id,
          action: this.postInfo.publisherTop ? 1 : 2,
          popShow: true,
          type: 'COVER',
          promoteStatus: this.postInfo.isPopping ? 1 : 2,
          status: this.postInfo.status || 0,
          publisherPopStatus: this.postInfo.publisherPopStatus || false,
          promoteInfo: {
            cover: this.postInfo.cover,
            title: this.postInfo.title,
            user: this.postInfo.publisher,
          },
        };
        if (!this.$route.query.uid) {
          this.isSetTop = true;
          // if (!this.checkVip()) {
          //   Toast('抱歉，只有会员才可以置顶')
          //   return;
          // }
          this.$store.commit('user/UPDATE_SETTOPDATA', data);
        }
      }, 1000);
    },
    getTouchEnd() {
      if (!this.isTop || this.isClear) {
        this.isClear = false;
        clearTimeout(this.setTop);
        return;
      }
      clearTimeout(this.setTop);
      if (this.isSetTop) {
        // this.isSetTop = false;
      } else {
        // this.$router.push({path:'/postDetails',query:{id:this.postInfo.id}})
        // this.goPlay(index);
      }
      // event.preventDefault();
    },
    clearTouch() {
      this.isClear = true;
      this.isSetTop = false;
      clearTimeout(this.setTop);
    },
  },
};
</script>
<style scoped lang="scss">
.post-warp {
  background: #ffffff;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .post-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .post-top-left {
      .post-avatar {
        width: 52px;
        height: 52px;
        border-radius: 50%;
        margin-right: 8px;
        position: relative;

        .certification {
          width: 16.1px;
          height: 16.2px;
          position: absolute;
          right: 0;
          bottom: 0;
          overflow: hidden;
          border-radius: 50%;
          -webkit-backface-visibility: hidden;
          -moz-backface-visibility: hidden;
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
        }
        .certificaBorder {
          position: absolute;
          top: -8.2px;
          width: 100%;
          font-size: 25px;
          z-index: 1;
        }
      }

      .isCertifica {
        border-radius: 50%;
        border: 1.5px solid #d82d0b;
      }

      .post-info {
        width: 200px;
        display: flex;
        flex-direction: column;

        .username_box {
          display: flex;
          align-items: center;

          .user_icon {
            width: 30px;
            height: 30px;
            padding-left: 5px;
            /deep/ .vanImage {
              background: #00000000 !important;
            }
          }

          span {
            font-size: 18px;
            letter-spacing: -0.41px;
            color: #333333;
          }
          .vipName {
            color: #ff678f;
          }
        }

        .fan {
          padding-top: 4px;

          .fan_text {
            font-size: 13px;
            color: #7c879f;
          }
        }

        .post_Introduction {
          font-size: 15px;
          letter-spacing: -0.08px;
          color: #7c879f;
          padding-top: 4px;

          svg {
            font-size: 16px;
            padding-right: 2px;
          }

          .location {
            max-width: 150px;
            padding-right: 16px;
            span {
              color: #666666;
            }
          }
          .pub_time {
            color: #666666;
          }
        }
      }
    }

    .post-top-right {
      display: inline-block;
      width: 56px;
      height: 25px;
      text-align: center;
      line-height: 25px;
      color: #ff82a2;
      border: 1px solid #ff82a2;
      border-radius: 20px;
      font-size: 14px;

      svg {
        font-size: 13px;
      }
    }
  }

  .forward-box {
    padding-bottom: 12px;
    font-size: 16px;

    .forward_name {
      padding: 0 6px 0 10px;
    }
    .forward_defaultText {
      font-family: 'PingFangHK-Thin';
    }
  }

  .post-text {
    padding-top: 12px;
    padding-bottom: 4px;
    display: flex;
    align-items: center;
    .topLabel {
      width: 43px;
      height: 21px;
      line-height: 21px;
      border-radius: 30px;
      text-align: center;
      color: #fff;
      background: rgb(255, 103, 143);
      font-size: 12px;
      margin-right: 4px;
    }
  }

  .text-btn {
    font-size: 16px;
  }

  .post-tag-box {
    display: flex;
    overflow-x: auto;
    width: 100%;
    color: #666666;
    white-space: nowrap; // 不换行

    a {
      color: #666666;
    }

    .post-tag {
      white-space: nowrap;
      padding: 2px 13px;
      font-size: 14px;
      background: #f4f4f4;
      margin-top: 10px;
      margin-right: 10px;
      border-radius: 20px;
      svg {
        font-size: 12px;
        padding-right: 4px;
      }
    }
  }

  .post_img {
    padding-top: 8px;
  }

  .cloumn_post_video {
    width: 170px !important;
    height: 271px !important;
  }

  .post_video {
    height: 188px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;

    .play_box {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
      top: 0;
      left: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .play_icon {
        width: 46px;
        height: 46px;
      }
    }
  }

  .cover-box-grid2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 6px;
    grid-column-gap: 6px;

    .img_item {
      height: 193px;
      border-radius: 8px;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }
  }

  .cover-box-grid3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 6px;
    grid-column-gap: 6px;
    padding-top: 8px;

    .img_item {
      height: 128px;
      border-radius: 8px;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }
  }

  .cover-box-grid3 .img_item:last-child {
    position: relative;
  }

  /deep/ .over {
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.6rem;
  }

  .views {
    // width: 102px;
    font-size: 12px;
    flex: 1;
    padding-top: 14px;
    display: flex;
    align-items: center;
    color: #666666;
    svg {
      font-size: 20px;
    }
  }

  .post_menu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 14px;

    .menu_item {
      text-align: center;
      // width: 130px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'PingFangHK-Regular';
      margin-right: 29px;
      color: #666666;

      svg {
        font-size: 20px;
      }

      > span {
        padding-left: 3px;
      }
    }
    .menu_item:last-child {
      margin-right: 0;
    }
  }
}
</style>
